import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-simple-flex-grid';

import { Container, FeaturesContainer, Icon } from './styled';
import { Heading3, Paragraph } from '../Typography';

const Feature = ({ data, hasBoxShadow }) => {
    return (
        <Container>
            <FeaturesContainer hasBoxShadow={hasBoxShadow}>
                <Heading3 marginBottom={hasBoxShadow ? '40px' : '64px'}>{data.title}</Heading3>
                <Paragraph bold center margin="40px 48px" marginbottomonsmall="24px">
                    {data.description}
                </Paragraph>
                <Row gutter={24}>
                    {data.feature.map(feature => (
                        <Col
                            key={feature.id}
                            xs={12}
                            sm={6}
                            md={12 / data.feature.length === 3 ? 3 : 4}
                        >
                            {feature.icon ? (
                                <Icon src={feature.icon.publicURL} alt="feature icon" />
                            ) : null}
                            <Paragraph bold center marginbottomonsmall="24px">
                                {feature.description}
                            </Paragraph>
                        </Col>
                    ))}
                </Row>
            </FeaturesContainer>
            {data.sidenote ? <Paragraph center>{data.sidenote}</Paragraph> : null}
        </Container>
    );
};

Feature.defaultProps = {
    hasBoxShadow: true,
};

Feature.propTypes = {
    data: PropTypes.shape({
        feature: PropTypes.arrayOf(
            PropTypes.shape({
                icon: PropTypes.shape({
                    publicURL: PropTypes.string.isRequired,
                }).isRequired,
                description: PropTypes.string.isRequired,
            })
        ).isRequired,
        title: PropTypes.string,
        description: PropTypes.string,
        sidenote: PropTypes.string,
    }).isRequired,
    hasBoxShadow: PropTypes.bool,
};

export default Feature;
