import styled, { css } from 'styled-components';

export const Container = styled.div`
    border-radius: 10px;
    margin-bottom: 70px;
    background: white;

    p {
        margin-top: 24px;
    }

    @media all and (max-width: 768px) {
        margin-bottom: 28px;
    }
`;

export const FeaturesContainer = styled.div`
    border-radius: 10px;
    ${({ hasBoxShadow }) =>
        hasBoxShadow
            ? css`
                  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
                  padding: 40px 16px;
              `
            : css`
                  padding: 40px 0px;

                  h3 {
                      font-size: 2.5rem;

                      @media all and (max-width: 768px) {
                          font-size: 1.125rem;
                      }
                  }
              `}

    h3 {
        text-align: center;
    }
`;

export const Icon = styled.img`
    display: block;
    margin: 0 auto 20px;
`;
