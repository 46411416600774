import React from 'react';
import PropTypes from 'prop-types';

import { Background } from './styled';
import Button from '../../global/ButtonLink';
import Container from '../../global/Container';
import Section from '../../global/Section';
import { Heading1, Paragraph } from '../../global/Typography';
import Feature from '../../global/Feature';

const FeatureComponent = ({ data, featureComponentFirst, lng }) => {
    const featureComponent = (
        <Container narrow={featureComponentFirst} marginTop={featureComponentFirst && '-170px'}>
            <Feature data={data.feature} />
        </Container>
    );

    return (
        <>
            <Background src={data.background.publicURL}>
                <Container narrow padding="0 15% 0 20px">
                    <Heading1 white marginBottom="24px">
                        {data.title}
                    </Heading1>
                    <Paragraph white marginBottom="24px" marginbottomonsmall="24px">
                        {data.description}
                    </Paragraph>
                    <Button
                        label={data.button.label}
                        href={data.button.href}
                        lng={lng}
                        primary={1}
                        marginbottomonsmall="24px"
                    />
                </Container>
            </Background>
            {featureComponentFirst && featureComponent}
            <Container narrow>
                {data.sections.map((section, index) => (
                    <Section
                        key={section.id}
                        section={section}
                        alignment={index % 2 === 0 ? 'left' : 'right'}
                        lng={lng}
                        smallTitle
                    />
                ))}
            </Container>
            {!featureComponentFirst && featureComponent}
        </>
    );
};

FeatureComponent.defaultProps = {
    featureComponentFirst: false,
};

FeatureComponent.propTypes = {
    data: PropTypes.shape({
        background: PropTypes.shape({
            publicURL: PropTypes.string.isRequired,
        }).isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        button: PropTypes.shape({
            label: PropTypes.string.isRequired,
            href: PropTypes.string.isRequired,
        }).isRequired,
        sections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        feature: PropTypes.shape({}).isRequired,
    }).isRequired,
    featureComponentFirst: PropTypes.bool,
    lng: PropTypes.string.isRequired,
};

export default FeatureComponent;
