import styled from 'styled-components';

export const Background = styled.div`
    background: url(${props => props.src});
    padding: 200px 0;
    background-size: cover;
    margin-bottom: 130px;

    @media all and (max-width: 1336px) {
        padding: 150px 0;
    }

    @media all and (max-width: 1024px) {
        margin-bottom: 28px;
        padding: 5em 0;

        a {
            margin-bottom: 0;
        }
    }

    @media all and (max-width: 601px) {
        padding: 3em 0;
    }
`;
